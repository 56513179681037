import React from 'react';
import CheckmarkIcon from '../assets/checkmark.png';
import ArrowIcon from '../assets/arrow.png';
import '../styles/style.css';
import '../styles/mediaqueries.css';

function Experience() {
    return (
        <section id="experience">
            <p className="section__text__p1">Explore My</p>
            <h1 className="title">Experience</h1>
            <div className="experience-details-container">
                <div className="about-containers">
                    <div className="details-container">
                        <h2 className="experience-sub-title">Backend Development</h2>
                        <div className="article-container">
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Flask</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Docker</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>RabbitMQ</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Kubernetes</h3>
                                    <p>Basic</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Javascript</h3>
                                    <p>Basic</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>MySQL</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="details-container">
                        <h2 className="experience-sub-title">General</h2>
                        <div className="article-container">
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Flutter</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Python</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>MongoDB</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Git</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>Linux</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                            <article>
                                <img src={CheckmarkIcon} alt="Checkmark icon" className="icon" />
                                <div>
                                    <h3>C#</h3>
                                    <p>Intermediate</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <img src={ArrowIcon} alt="Arrow icon" className="icon arrow" onClick={() => window.location.href = './#projects'} />
        </section>
    );
}

export default Experience;
