import React, { useState, useEffect } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProjectCard from "../components/ProjectCard";
import Contacts from "../components/Contacts";
import { fetchData, HOSTNAME } from '../api/api'; // Import HOSTNAME from api.js
import '../styles/Projects.css';

function Projects() {
    const [projectData, setProjectData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [projectsPerPage] = useState(12);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const data = await fetchData('/projects');
                // Extracting the project information from the fetched data
                const projects = data.message.map(project => ({
                    id: project.id,
                    title: project.name,
                    description: project.description,
                    thumbnail: `${HOSTNAME}/${project.thumbnail}` // Use HOSTNAME to construct thumbnail URL
                }));
                setProjectData(projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []); // Empty dependency array ensures this effect runs only once after component mounts

    // Get current projects
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = projectData.slice(indexOfFirstProject, indexOfLastProject);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Previous page
    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Next page
    const goToNextPage = () => {
        if (currentPage < Math.ceil(projectData.length / projectsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    // First page
    const goToFirstPage = () => setCurrentPage(1);

    // Last page
    const goToLastPage = () => setCurrentPage(Math.ceil(projectData.length / projectsPerPage));

    return (
        <>
            <Header />
            <div className="projects-map">
                {currentProjects.map(project => (
                    <ProjectCard key={project.id} project={project} />
                ))}
                {/* Fill empty spaces with placeholders */}
                {Array.from({ length: projectsPerPage - currentProjects.length }).map((_, index) => (
                    <div key={index} className="placeholder"></div>
                ))}
            </div>
            <div className="pagination">
                <button onClick={goToFirstPage} disabled={currentPage === 1} title="Go to First Page">{'<<'}</button>
                <button onClick={goToPrevPage} disabled={currentPage === 1} title="Go to Previous Page">{'<'}</button>
                <span>{currentPage}</span>
                <button onClick={goToNextPage} disabled={currentPage === Math.ceil(projectData.length / projectsPerPage)} title="Go to Next Page">{'>'}</button>
                <button onClick={goToLastPage} disabled={currentPage === Math.ceil(projectData.length / projectsPerPage)} title="Go to Last Page">{'>>'}</button>
            </div>
            <Contacts />
            <Footer />
        </>
    );
}

export default Projects;
