import React from 'react';
import '../styles/style.css';
import '../styles/mediaqueries.css';
import profilePicture from "../assets/Picture1.png";
import linkedinIcon from "../assets/linkedin.png";
import githubIcon from "../assets/github.png";
import resumePDF from "../assets/Resume.pdf";

function Profile() {
    return (
        <section id="profile">
            <div className="section__pic-container">
                <img src={profilePicture} alt="Samwel Waithaka profile picture"/>
            </div>
            <div className="section__text">
                <p className="section__text__p1">Hello, I'm</p>
                <h1 className="title">Samwel Waithaka</h1>
                <p className="section__text__p2">Computer Science student</p>
                <div className="btn-container">
                    <button
                        className="btn btn-color-2"
                        onClick={() => window.open(resumePDF)}
                    >
                        Download CV
                    </button>
                    <button
                        className="btn btn-color-1"
                        onClick={() => window.location.href = '#contact'}
                    >
                        Contact Info
                    </button>
                </div>
                <div id="socials-container">
                    <img
                        src={linkedinIcon}
                        alt="My LinkedIn profile"
                        className="icon"
                        onClick={() => window.open('https://linkedin.com/in/idrisfallout/')}
                    />
                    <img
                        src={githubIcon}
                        alt="My Github profile"
                        className="icon"
                        onClick={() => window.open('https://github.com/IdrisFallout')}
                    />
                </div>
            </div>
        </section>
    );
}

export default Profile;
