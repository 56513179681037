// api/api.js

const HOSTNAME = 'https://portfolio.waithakasam.com';
const API_URL = `${HOSTNAME}/api/v1`;

export const fetchData = async (endpoint) => {
    try {
        const response = await fetch(`${API_URL}${endpoint}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        // You can handle errors here, such as displaying an error message or logging them
        return null;
    }
};

export { HOSTNAME };