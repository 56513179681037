import React from 'react';
import '../styles/ProjectCard.css';

function ProjectCard({project}) {
    function handleClick() {
        window.location.href = `/projects/${project.id}`;
    }

    return (
        <div className="project-card" onClick={handleClick}>
            <div className="thumbnail-div">
                <img src={project.thumbnail} alt="Project Thumbnail" className="project-thumbnail"/>
            </div>
            <div className="project-details">
                <h2 className="project-title">{project.title}</h2>
                <p className="project-description">{project.description}</p>
            </div>
        </div>
    );
}

export default ProjectCard;
