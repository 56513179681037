import React from 'react';
import Header from "../components/Header";
import Profile from "../components/Profile";
import About from "../components/About";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";

function Landingpage() {
    return (
        <>
            <Header />
            <Profile />
            <About />
            <Experience />
            <Projects />
            <Contacts />
            <Footer />
        </>
    );
}

export default Landingpage;
