import React from 'react';
import '../styles/style.css';
import '../styles/mediaqueries.css';
import aboutMePic from "../assets/aboutMe.jpg";
import experienceIcon from "../assets/experience.png";
import educationIcon from "../assets/education.png";
import arrowIcon from "../assets/arrow.png";

function About() {
    return (
        <section id="about">
            <p className="section__text__p1">Get To Know More</p>
            <h1 className="title">About Me</h1>
            <div className="section-container">
                <div className="section__pic-container">
                    <img
                        src={aboutMePic}
                        alt="Profile picture"
                        className="about-pic"
                    />
                </div>
                <div className="about-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <img
                                src={experienceIcon}
                                alt="Experience icon"
                                className="icon"
                            />
                            <h3>Experience</h3>
                            <p>As a backend developer, I'm passionate about crafting IoT and robotics solutions,
                                leveraging my expertise to drive innovation in these cutting-edge fields.</p>
                        </div>
                        <div className="details-container">
                            <img
                                src={educationIcon}
                                alt="Education icon"
                                className="icon"
                            />
                            <h3>Education</h3>
                            <p>Data Science, Moringa School</p>
                            <p>B.Sc. Computer Science at Machakos University</p>
                        </div>
                    </div>
                    <div className="text-container">
                        <p>
                            I'm Samwel Waithaka, soon to graduate with a BSc in Computer Science from Machakos
                            University. My academic journey has fueled my passion for backend systems, robotics
                            , IoT, and AI, driving me to develop a versatile skill set in full-stack development.
                            I've worked on designing user-friendly interfaces, optimizing database performance,
                            and creating efficient systems that blend form and function. Beyond coding, I’m
                            inspired by how technology intersects with art, particularly through 3D design and
                            game development, which I pursue in my free time. This creative perspective enriches
                            my technical work, allowing me to approach problems from unique angles. I'm driven by
                            a desire to innovate and make a positive impact. I'm excited to explore opportunities
                            to collaborate with like-minded individuals and organizations on cutting-edge projects.
                            Let’s connect and build something remarkable together!
                        </p>
                    </div>
                </div>
            </div>
            <img
                src={arrowIcon}
                alt="Arrow icon"
                className="icon arrow"
                onClick={() => window.location.href = '#experience'}
            />
        </section>
    );
}

export default About;
