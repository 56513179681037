import React from 'react';
import '../styles/style.css';
import '../styles/mediaqueries.css';

// Import images
import nggImage from '../assets/ngg.png';
import fileImage from '../assets/file.png';
import hbnbImage from '../assets/hbnb.png';
import arrowImage from '../assets/arrow.png';

function Projects() {
    return (
        <section id="projects">
            <p className="section__text__p1">Browse My Recent</p>
            <h1 className="title">Projects</h1>
            <div className="experience-details-container">
                <div className="about-containers">
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={nggImage}
                                alt="TukoNewsApp"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">TukoNewsApp</h2>
                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => window.open('https://github.com/IdrisFallout-Selfhosted/TukoNewsApp')}
                            >
                                Github
                            </button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={fileImage}
                                alt="MKSUPortal"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">MKSUPortal</h2>
                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => window.open('https://github.com/IdrisFallout/MKSUPortal')}
                            >
                                Github
                            </button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={hbnbImage}
                                alt="GeminiAIChatBot"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">GeminiAIChatBot</h2>
                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => window.open('https://github.com/IdrisFallout-Selfhosted/GeminiAIChatBot_App')}
                            >
                                Github
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={arrowImage}
                alt="Arrow icon"
                className="icon arrow"
                onClick={() => window.location.href = './#contact'}
            />
            <div className="more_projects">
                <button
                    className="btn btn-color-2 project-btn"
                    onClick={() => window.location.href = './projects'}
                >
                    Explore More
                </button>
            </div>
        </section>
    );
}

export default Projects;
