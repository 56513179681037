import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { fetchData, HOSTNAME } from '../api/api';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contacts from "../components/Contacts";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/ProjectDetails.css';

function ProjectDetails() {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        fetchData(`/projects/${id}`)
            .then(data => {
                setProject(data.message);
                if (data.message) {
                    document.title = data.message.name;
                }
            })
            .catch(error => console.error('Error fetching project details:', error));
    }, [id]);

    const getMediaType = (url) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'flv', 'mkv'];
        const parts = url.split('/');
        const filename = parts[parts.length - 1];
        const extension = filename.split('.').pop().toLowerCase();

        if (imageExtensions.includes(extension)) {
            return 'image';
        } else if (videoExtensions.includes(extension)) {
            return 'video';
        } else {
            return 'unknown';
        }
    };

    // Custom component for rendering links in Markdown
    const renderLink = ({ children, href }) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );

    // Custom component for rendering images in Markdown
    const renderImage = ({ alt, src, title }) => (
        <img src={src} alt={alt} title={title} className="responsive-image" />
    );

    // Components object for ReactMarkdown
    const components = {
        a: renderLink,
        img: renderImage,
    };

    return (
        <div>
            <Header />
            <div className="project-details-container">
                {project ? (
                    <>
                        <div className="project-heading">
                            <h1>{project.name}</h1>
                            <p>{project.description}</p>
                        </div>
                        <div className="status-container non-interactable">
                            <p className={`project-status ${project.status.toLowerCase().replace(/\s/g, '-')}`}>{project.status}</p>
                        </div>
                        <div className="category-container non-interactable">
                            <p className="category-title">Category</p>
                            <p className="category-value">{project.link.split('/')[0]}</p>
                        </div>
                        <div className="slider-container">
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                dynamicHeight={false}
                                autoPlay={true}
                                interval={10000}
                                infiniteLoop={true}
                                stopOnHover={false}
                                emulateTouch={true}
                                width="80%"
                                height="45%" // Adjust the height as needed
                            >
                                {project.media.map((media, index) => (
                                    <div key={index} style={{ width: '100%', height: '100%' }}>
                                        {getMediaType(`${HOSTNAME}/api/v1/${media}`) === 'video' ? (
                                            <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', aspectRatio: 16/9 }}>
                                                <source src={`${HOSTNAME}/api/v1/${media}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img src={`${HOSTNAME}/api/v1/${media}`} alt={`Media ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover', aspectRatio: 16/9 }} />
                                        )}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <ReactMarkdown className="notes-content" components={components}>
                            {project.notes}
                        </ReactMarkdown>
                    </>
                ) : (
                    <p></p>
                )}
            </div>
            <Contacts />
            <Footer />
        </div>
    );
}

export default ProjectDetails;
